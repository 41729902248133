export const getTaskLogo = (task) => {
    /*
    Given a task, this function return a vendor logo for that task if the task contains tags that have logos.
    */
    let selected_logo_html = undefined;
    let logo_list = getStaticLogoList();
    let has_logo_tag_dict = getHasLogoTagDict();

    // If the URL contains a tag parameter, we need to prefer to display the logo based on the order specified by that parameter
    // instead of the order specified in the task's tag list.  For example, if the task's tags contains ['aws','aim'], and the 
    // URL contains tag=aim, we need to show the AIM's logo

    const route = useRoute();
    let short_list = [];
    if (route.query.hasOwnProperty('tag')) {
        short_list = route.query.tag.split(/,/).filter((tag) => { // Iterate over the task's tags, and keep only the tags that we have logo for.
            if (has_logo_tag_dict.hasOwnProperty(tag.toLowerCase())) {
                return true;
            } else {
                return false;
            };
        });
        // short_list now contains a list of tags from the URL that we have logo for.

    }

    task.tags.forEach((tag) => { // Iterate over the task's tags, and add to short_list the tag that we have logo for
        if (has_logo_tag_dict.hasOwnProperty(tag.toLowerCase())) {
            short_list.push(tag.toLowerCase());
        }
        // Now, short_list contains a list of tags from the URL that we have logo for, and the task's tags that we have logo for with 
        // the tags from the URL at the front of the list.
    });

    if (short_list.length > 0) {
        let selected_tag = has_logo_tag_dict[short_list[0].toLowerCase()]; // use the first item from short_list
        let value = logo_list[selected_tag]['logo'];
        if (value.startsWith('<i')) {
            // Looks to be something like a FontAwesome icon such as: <i class="fa-brands fa-aws"></i> which we can directly use.
            selected_logo_html = value;
        } else {
            selected_logo_html = `<span class="vendor_logo_span" style="background: no-repeat center/contain url(${value})"></span>`;
        }
    }

    return selected_logo_html;
}