export const getHasLogoTagDict = () => {
    /*
    This function retrieve the tag mapping by calling getTagMapping().
    It then creates a dictionary who keys are synonyms, and values are tags that can be used to 
    look up logo defined in getStaticLogoList.

    We cache the result of this function using a state.
    */

    let has_logo_tag_state = useHasLogoTagState();
    if ((typeof(has_logo_tag_state.value) == 'undefined') || (Object.keys(has_logo_tag_state.value) == 0)) {
        let result = {};

        let mapping = getTagMapping();
        Object.keys(mapping).forEach((tag) => {
            let aliases = mapping[tag];
            aliases.forEach((alias) => {
                result[alias] = tag;
            })
        });

        has_logo_tag_state.value = result;
    }

    return has_logo_tag_state.value;    
}